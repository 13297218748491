import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class AppSetting {
  siteUrl = 'https://archonline.co.il/'; 
  // siteUrl = 'http://localhost:63061/';
  reCaptchaKey = '6LeqIkEUAAAAALurGbL0OuWO90heGJuZr8bunHcE';
  // tranzilaName = 'gilarchtest/';
  tranzilaName = 'ttxgilarch/';
  numOfArticlePerPage = 10;
  phone = '9720512688307';
  scrollY = 0;
  displayChatBot = false;
}
