import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppSetting } from '../global/appsetting';
import { Config } from '../models/configs/configs';

@Injectable()
export class LoginService {
  userToken = {
    data: {
      item: {
      }
    }
  };
  constructor(private http: HttpClient,
    public appsetting: AppSetting
  ) { }
  logIn(pass: string) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      const params = new HttpParams().set('pass', pass);
      return this.http.post(this.appsetting.siteUrl + 'LoginApi/login',
      { headers: headers }, { params: params }).subscribe((res: Config) => {
        this.userToken.data.item = res;
        resolve(res);
      }, error => {
        reject(error);
      });
    });

  }
  GenerateNewToken(oldToken: string) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      const params = new HttpParams().set('oldToken', oldToken);
      return this.http.post(this.appsetting.siteUrl + 'LoginApi/GenerateNewToken',
      { headers: headers }, { params: params }).subscribe((res: Config) => {
        this.userToken.data.item = res;
        resolve(res);
      }, error => {
        reject(error);
      });
    });

  }
}
