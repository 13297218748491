import { Injectable } from '@angular/core';
import { AppSetting } from './../global/appsetting';
import { HttpClient } from '@angular/common/http';
import { GaAnalyticsService, EVENT_TYPES } from './ga-analytics.service';


@Injectable()
export class ClientsService {

  constructor(private http: HttpClient, public appsetting: AppSetting, private gaAnalyticsService: GaAnalyticsService) { }
  GetClientsCount(token: string) {
    return fetch(this.appsetting.siteUrl + 'ClientApi/GetClientsCount', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    }).then(res => res.json()).catch(err=>{
      this.gaAnalyticsService.logEventToGa('GetClientsCount', 'ClientsService', EVENT_TYPES.Error);
    });
  }
  GetAllClients(token: string) {
    return fetch(this.appsetting.siteUrl + 'ClientApi/GetAllClients', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    }).then(res => {
      if (res) {
        return res.json();
      } else {
        return null;
      }
    }).catch(err=>{
      this.gaAnalyticsService.logEventToGa('GetAllClients', 'ClientsService', EVENT_TYPES.Error);
    });

  }
}

