export class Config {
    Id: number;
    Key: string;
    SValue: string;
    Ivalue: number;
    Updated: string;
    created: string;
    Active: boolean;
  }
  export class ConfigView {
    ConfigItem: Config;
    Pass: string;
    Token: string;
  }