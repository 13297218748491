import { Injectable } from "@angular/core";
import { AppSetting } from "./../global/appsetting";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { shareReplay } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";
import { OptionView, Product, ProductView } from "../models/products/products";

@Injectable()
export class ProductsService {
  FinalSumIncludeAll = { data: { item: 0 } };

  productView$: Observable<ProductView>;
  productList$ = new BehaviorSubject<Product[]>([]);
  allProductsAndOptions$ = new BehaviorSubject<ProductView[]>([]);
  currentProductViewProductId = -1;
  headers = new HttpHeaders({ "Content-Type": "application/json" });

  constructor(
    private _http: HttpClient, 
    private _appSetting: AppSetting) {}

  GetProductList(): Observable<Product[]> {
    return this._http
      .get<Product[]>(this._appSetting.siteUrl + "api/product/products", {
        headers: this.headers,
      })
      .pipe(shareReplay());
  }

  GetAllProductsAndOptions(): Observable<ProductView[]> {
    return this._http.get<ProductView[]>(
      this._appSetting.siteUrl + "api/product/products-and-options",
      { headers: this.headers }
    );
  }

  GetProductOptionsById(productId): Observable<OptionView[]> {
    return this._http.get<OptionView[]>(
      this._appSetting.siteUrl + "api/product/product-options/" + productId,
      { headers: this.headers }
    );
  }

  GetProductViewByProdId(productId: number): Observable<ProductView> {
    if (this.currentProductViewProductId !== productId) {
      this.productView$ = this._http
        .get<ProductView>(
          this._appSetting.siteUrl + "api/product/product-view/" + productId,
          { headers: this.headers }
        )
        .pipe(shareReplay());
    }
    this.currentProductViewProductId = productId;
    return this.productView$;
  }

  RemoveProdPictureById(id, token): Observable<boolean> {
    const options = {
      headers: this.headers,
      body: {
        token,
      },
    };
    return this._http.delete<boolean>(
      this._appSetting.siteUrl + "api/product/picture/" + id,
      options
    );
  }

  RemoveOptionById(id, token): Observable<boolean> {
    const options = {
      headers: this.headers,
      body: {
        token,
      },
    };
    return this._http.post<boolean>(
      this._appSetting.siteUrl + "api/product/option/" + id,
      options
    );
  }

  RemoveDynamicOption(id, token): Observable<boolean> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: token,
    });
    const options = {
      headers,
    };
    return this._http.delete<boolean>(
      this._appSetting.siteUrl + "api/product/dynamic-option/" + id,
      options
    );
  }

  RemoveDynamicOptionItem(id, token): Observable<boolean> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: token,
    });
    const options = {
      headers,
    };
    return this._http.delete<boolean>(
      this._appSetting.siteUrl + "api/product/dynamic-option-item/" + id,
      options
    );
  }

  RemoveFixPriceById(id, token): Observable<boolean> {
    const options = {
      headers: this.headers,
      body: {
        token,
      },
    };
    return this._http.delete<boolean>(
      this._appSetting.siteUrl + "api/product/fixprice/" + id,
      options
    );
  }

  RemoveExistFormatById(id, token): Observable<boolean> {
    const options = {
      headers: this.headers,
      body: {
        token,
      },
    };
    return this._http.delete<boolean>(
      this._appSetting.siteUrl + "api/product/existformat/" + id,
      options
    );
  }

  RemoveAvailbleFileFormatById(id, token): Observable<boolean>  {
    const options = {
      headers: this.headers,
      body: {
        token,
      },
    };
    return this._http.delete<boolean>(
      this._appSetting.siteUrl + "api/product/available-file-format/" + id,
      options
    );
  }

  RemoveDayAndPricesById(id, token: string): Observable<boolean>  {
    const options = {
      headers: this.headers,
      body: {
        token,
      },
    };
    return this._http.delete<boolean>(
      this._appSetting.siteUrl + "api/product/day-and-prices/" + id,
      options
    );
  }

  InsertProduct(productView: ProductView, userPass: string): Observable<number> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).append("token", userPass);
    return this._http.post<number>(
      this._appSetting.siteUrl + "api/product/product",
      productView,
      { headers: headers }
    );
  }

  UpdateOptionPrice(optionView: OptionView, userPass: string): Observable<boolean>  {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).append("token", userPass);
    return this._http.put<boolean>(
      this._appSetting.siteUrl + "api/product/update/option/price",
      optionView,
      { headers: headers }
    );
  }

  RemoveQuestion(id: number, pass: string): Observable<boolean>  {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: {
        pass,
      },
    };
    return this._http.delete<boolean>(
      this._appSetting.siteUrl + "api/product/remove-question/" + id,
      options
    );
  }

}
