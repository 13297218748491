import { OnlineFooterComponent } from './components/online-design/online-footer/online-footer.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app.routing.module';

import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './modules/core/core.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent, OnlineFooterComponent,
  ],
  imports: [
    RouterModule,
    NgbCarouselModule,
    FormsModule,
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule.forRoot(),
  ],
  exports: [],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
