import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { filter, map } from "rxjs";
import { ArticlesService } from "./articles.service";
import { ProductsService } from "./products.service";
import { ProductView } from "../models/products/products";
import { ArticleView } from "../models/articles/articles";

@Injectable()
export class MetaTagService {
  constructor(
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private articlesService: ArticlesService,
    private route: ActivatedRoute,
    private productsService: ProductsService
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.rootRoute(this.route)),
        filter((route: ActivatedRoute) => route.outlet === "primary")
      )
      .subscribe((val: ActivatedRoute) => {
        const path = window.location.pathname;
        if (path === "/admin/login") {
          this.updateMetaTags("כניסה למערכת הניהול", null, null);
        } else if (path === "/admin/price-list") {
          const mainImageUrl =
            window.location.origin +
            "/assets/online_design/" +
            "logoE_short_fav.png";
          this.updateMetaTags("עדכון מחירים", "ניהול", mainImageUrl);
        } else if (path === "/admin/trns-admin") {
          this.updateMetaTags("ניהול הזמנות", null, null);
        } else if (path === "/archonline_product_list") {
          const mainImageUrl =
            window.location.origin +
            "/assets/online_design/" +
            "logoE_short_fav.png";
          this.updateMetaTags(
            "כל השירותים האדריכליים במקום אחד",
            "תוכנית לשיפוץ חדר אמבטיה, הנמכת תקרה, תכנון מטבח, תאורה לסלון ופינת אוכל ועוד שירותים אדריכליים. הכל במחיר אחד החל מ-115 שח",
            mainImageUrl
          );
        } else if (path === "/aoc/archonline_basket") {
          this.updateMetaTags("סל קניות", "כל מה שרכשת מתכנון אונליין", null);
        } else if (path.includes("aoc/articles/")) {
          const id =
            val.snapshot.params.id == null ? -1 : val.snapshot.params.id;
          if (id > -1) {
            this.articlesService
              .GetArticleViewById(id)
              .subscribe((res: ArticleView) => {
                this.updateMetaTags(
                  res.Article.Subject,
                  res.Article.MetaDescription,
                  window.location.origin +
                    "/assets/uploaded_pic/SmallPic/" +
                    res.Article.Id +
                    "/" +
                    res.Article.SmallPic
                );
              });
          }
        } else if (path === "/aoc/archonline_article_list") {
          this.updateMetaTags(
            "הטיפים שלנו לעיצוב הבית שלכם",
            "מה זה שינויי דיירים? איך מנמיכים תקרה? איך עושים תכנון מטבח נכון? איך עושים שיפוץ אמבטיה? קראו את כל הטיפים שלנו",
            null
          );
        } else if (path.includes("p/product")) {
          const productId = parseInt(val.snapshot.params.id, 10);
          this.productsService
            .GetProductViewByProdId(productId)
            .subscribe((res: ProductView) => {
              let mainImageUrl = "";
              if (res.ProductPictureItems[0].Path) {
                mainImageUrl =
                  window.location.origin +
                  "/assets/online_design/product_exemple/" +
                  res.ProductPictureItems[0].Path;
              }
              this.updateMetaTags(
                res.ProductItem.Name,
                res.ProductItem.MetaDescription,
                mainImageUrl
              );
            });
        }
      });
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  updateMetaTags(title, description, img) {
    this.titleService.setTitle("תכנון אונליין - " + title);
    this.meta.updateTag({
      property: "og:title",
      content: "תכנון אונליין - " + title,
    });
    this.meta.updateTag({ property: "og:url", content: window.location.href });
    this.meta.updateTag({ property: "og:description", content: description });
    this.meta.updateTag({ property: "description", content: description });
    if (!img) {
      img =
        window.location.origin +
        "/assets/online_design/" +
        "logoE_short_fav.png";
    }
    this.meta.updateTag({ property: "og:image", content: img });
  }
  resetMetaTag() {
    const description =
      "תכנון אונליין הינו אתר ייחודי אשר הוקם תחת חסות סטודיו גיל אדריכלות, ומספק שירות" +
      " ייחודי של תכנון אדריכלי אונליין המנגיש את השירות האדריכלי לכל כיס.    השימוש בתכנון אונליין, יספק לכם אדריכל אישי אונליין" +
      " אשר יכין עבורכם תכניות ביצוע מפורטות המותאמות לכם באופן אישי ולכל הצרכים שלכם על" +
      " ידי שילוב של טכנולוגיה נגישה, מענה אנושי ומהיר ועמידה בלוחות הזמנים.";
    this.titleService.setTitle("תכנון אונליין");
    this.meta.updateTag({ name: "og:title", content: "תכנון אונליין" });
    this.meta.updateTag({ name: "og:url", content: window.location.href });
    this.meta.updateTag({ name: "og:description", content: description });
    this.meta.updateTag({ name: "description", content: description });
    this.meta.updateTag({
      name: "og:image",
      content:
        "https://www.archonline.co.il/assets/online_design/logoE_short_fav.png",
    });
  }
}
