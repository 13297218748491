import { ChatService } from './../../services/chat.service';
import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailServiceService } from '../../services/email-service.service';
import { CommentsService } from '../../services/comments.service';
import { LoaderService } from '../../services/loader.service';
import { ProductsService } from '../../services/products.service';
import { AppSetting } from '../../global/appsetting';
import { ClientsService } from '../../services/clients.service';
import { CouponService } from '../../services/coupon.service';
import { Title } from '@angular/platform-browser';
import { ArticlesService } from '../../services/articles.service';
import { ServerConfigService } from '../../services/server-config.service';
import { MetaTagService } from '../../services/meta-tag.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpIntercept } from '../../shared/http-intercept';
import { HeaderService } from '../../services/header.service';
import { LoginService } from '../../services/login.service';
import { GaAnalyticsService } from 'src/app/services/ga-analytics.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,    
    HttpClientModule
  ], 
  providers: [
    HeaderService,
    EmailServiceService,
    CommentsService,
    LoaderService,
    AppSetting,
    LoginService,
    ProductsService,
    ClientsService,
    CouponService,
    Title,
    ArticlesService,
    ServerConfigService,
    MetaTagService,
    ChatService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpIntercept,
      multi: true
    }
  ], 
  exports: []
})
export class CoreModule {
  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
  static forRoot(): ModuleWithProviders<CoreModule> {
  return {
    ngModule: CoreModule,
    providers: [ HeaderService, 
      EmailServiceService,
      CommentsService,
      LoaderService,
      AppSetting,
      ProductsService,
      ClientsService,
      CouponService,
      Title,
      LoginService,
      ArticlesService,ChatService,
      ServerConfigService,
      MetaTagService,
      GaAnalyticsService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpIntercept,
        multi: true
      } ]
  }
} }
