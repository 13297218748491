import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  msgList: Array<ChatMsg> = [];
  roomId = '';
  userName;
  userType = 'user';
  adminIsOnLive= false;
  inConversition = false;
  
  constructor() { }
}
export class ChatMsg {
  text: string;
  date: Date;
  userName: string;
  roomId: string;
  userType: string;
}