import { ServerConfigService } from './../../../services/server-config.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-footer',
  templateUrl: './online-footer.component.html',
  styleUrls: ['./online-footer.component.scss']
})
export class OnlineFooterComponent implements OnInit {
  currentYear = 2000;
  version: string;

  constructor(private serverConfigService: ServerConfigService) { }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    this.serverConfigService.GetConfigByKey('version').subscribe((res: any)=>{
      if(res){
        this.version = 'Version: ' + res.SValue;
      }
      else{
        this.version = 'Version: 0.0.0';
      }
    });
  }

}
