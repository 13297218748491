import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  NavigationStart,
} from "@angular/router";
import { filter, map, takeWhile } from "rxjs/operators";
import {
  GaAnalyticsService,
  EVENT_TYPES,
} from "./services/ga-analytics.service";
import { ProductsService } from "./services/products.service";
import { LoaderService } from "./services/loader.service";

declare const gtag: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  test: string;
  private _isComponentActive = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _productService: ProductsService,
    public loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this._scrollToTopOnChangeRout();
    this._setProductList();
  }

  ngAfterViewInit(): void {
    if (document.querySelector("#hide-after-loading")) {
      document.querySelector("#hide-after-loading").remove();
    }

    if (document.querySelectorAll(".hide-after-loading").length > 0) {
      document.querySelectorAll(".hide-after-loading").forEach((item) => {
        item.remove();
      });
    }
  }

  private _scrollToTopOnChangeRout(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === "primary")
      )
      .subscribe((event) => {
        if ((<any>event.component).name != "OnlineHomeComponent") {
          document.querySelector("html").scrollTop = 0;
          window.scrollTo(0, 0);
        }
      });
  }

  private _setProductList(): void {
    this.loaderService.displayLoader(false);
    this._productService
      .GetProductList()
      .pipe(takeWhile(() => this._isComponentActive))
      .subscribe({
        next: (products) => {
          this.loaderService.hideLoader();
          this._productService.productList$.next(products);
        },
        error: () => {
          this.loaderService.hideLoader();
          alert('התרחשה תקלה בטעינת הנתונים. אנא פנה למנהל');
        },
      });
  }

  ngOnDestroy(): void {
    this._isComponentActive = false;
  }
}
